import React, {Component} from "react";
import { Col, Row } from "react-bootstrap";

export default class MobileNav extends Component {
    render(){
        return(
            <div className="mobile-footer-container">
                <Row className="no-gutters">
                    <Col className="col-4 call-us">
                        <a href="tel:1300875978">Call us</a>
                    </Col>
                    <Col className="col-4 email-us">
                        <a href="mailto:hello@diviv.com.au">Email us</a>
                    </Col>
                    <Col className="col-4 find-us">
                        <a href="https://goo.gl/maps/3AwjAKDbhKfAzEw19" target="_blank" rel="noreferrer">Find us</a>
                    </Col>
                </Row>
            </div>
        )
    }
}


