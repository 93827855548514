import React, { Component } from "react";
import { Footer } from "../global/footer";

const HomeLayout = ({ textColor, children }) => {
  return (
    <div className="layout--home">
      {children}
      <Footer />
    </div>
  );
};

HomeLayout.defaultProps = {
  textColor: "#fff",
};

export default HomeLayout;
