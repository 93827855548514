// @ts-ignore

import React, {
  createRef,
  RefObject,
  useState,
  useEffect,
  useRef,
} from "react";
import { Container, Col, Row } from "react-bootstrap";

import { graphql, useStaticQuery } from "gatsby";
import SEO from "../components/shared/seo";
import HomeLayout from "../components/layouts/layout-home";
import {
  FadeUpBox,
  FadeIn,
  FadeUpBottom,
} from "../components/shared/transitions";
import { ServicesList } from "../components/services-list/services-list";
import { Scene, Controller } from "react-scrollmagic";
import { Parallax, ParallaxBanner } from "react-scroll-parallax";
import { Helmet } from "react-helmet";
import { PrimaryButton } from "../components/buttons/primary-button";
import CaseCarousel from "../components/case-carousel/case-carousel";

import { FiArrowRight, FiArrowDown } from "react-icons/fi";
import * as Graphics from "../components/svg/graphics";
import { MenuContext } from "../components/global/menu";
import ScrollIndicator from "../components/shared/scroll-indicator";
import { LogoText } from "../components/shared/logo";
import MobileNav from "../components/shared/mobile-nav";
import technicalGraphic from "../images/technical-graphic.svg";
import { PoseGroup } from "react-pose";
import * as smoothscroll from "smoothscroll-polyfill";
import natureVideo from "../video/nature-light.mp4";
import introVideo from "../video/UX-promo-720p.mp4";
import closeBtn from "../images/close-btn.svg";
import videoPosterIntro from "../images/video-poster-intro.png";

function promoModal() {
  var element = document.getElementById("video-promo");
  element.classList.add("active");
  document.body.classList.add("noScroll");
}

function cancelBtn2() {
  var element = document.getElementById("video-promo");
  element.classList.remove("active");
  document.body.classList.remove("noScroll");
}

function Index(props) {
  const [animate, setAnimate] = useState(false);

  const castStudySectionRef: RefObject<HTMLDivElement> = createRef();

  useEffect(() => {
    setTimeout(() => {
      setAnimate(true);
    }, 200);

    smoothscroll.polyfill();
  });

  const homeImages = useStaticQuery(graphql`
    query {
      homeBanner: file(relativePath: { eq: "home-banner.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      homeImage2: file(relativePath: { eq: "diviv-22-filter.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      bgTexture: file(relativePath: { eq: "bg-texture.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      Pindan: file(relativePath: { eq: "card-pindan.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pindanLogo: file(relativePath: { eq: "pindan-logo-sm.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      visibuyLogo: file(relativePath: { eq: "visibuy-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      visibuy: file(relativePath: { eq: "visibuy-case-study-tile.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      investTradeLogo: file(relativePath: { eq: "invest-trade-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      investTrade: file(relativePath: { eq: "invest-trade-tile.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      energyAction: file(relativePath: { eq: "card-energyaction.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      eaLogo: file(relativePath: { eq: "ea-logo-sm.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      wesfarmers: file(relativePath: { eq: "card-wesfarmers.png" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      wesfarmersLogo: file(relativePath: { eq: "wesfarmers-logo-sm.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      stJohnLogo: file(relativePath: { eq: "st-john-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      energyActionLogo: file(relativePath: { eq: "ea-logo-sm.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      stJohnVas: file(relativePath: { eq: "card-vas.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      stJohn: file(relativePath: { eq: "card-stjohn.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      divivLogo: file(relativePath: { eq: "logo-icon-cyan.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      wesfarmersSustainability: file(relativePath: { eq: "wesfarmers-case-study-tile.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      murdochLogo: file(relativePath: { eq: "murdoch-logo-sm.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      murdochCourse: file(relativePath: { eq: "Murdoch-case-study-tile.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      murdoch: file(relativePath: { eq: "card-murdoch.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
      {console.log("loaded")}
      <Helmet>
        <meta name="robots" content="index, follow" />
        <meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
        <meta name="bingbot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
        <meta property="og:title" content="Perth's Best Web Development and Support" />
        <meta property="og:url" content="https://www.diviv.com.au" />
        <meta property="og:description" content="Trust your web development and support to the local professionals. Fast and Responsive Service ✔ | Customer Satisfaction Guaranteed ✔" />
        <meta property="og:type" content="website" />
      </Helmet>
      <SEO
        title="Diviv Group Perth | Web and Application Development and Support Specialists"
        description="Diviv Group are a trusted name in Perth for web dev and support. Experienced Team ✔ | Customer Satisfaction Guaranteed ✔"
        keywords="Perth Web Development, Application Development, Web Development, Web support, website support"
      />

      <HomeLayout>
        <section className="hero">
          <div className="full-height--background">
            <ParallaxBanner
              layers={[
                {
                  image: homeImages.homeBanner.childImageSharp.fluid.src,
                  amount: 0.5,
                },
              ]}
              style={{
                height: "100%",
              }}
            />
            <div className="text-overlay">
              <Container>
                <Row>
                  <Col>
                    <PoseGroup>
                      {animate && [
                        <FadeUpBox
                          pose={animate ? "enter" : "exit"}
                          key="fade-up"
                        >
                          <FadeIn duration={200}>
                            <LogoText />
                          </FadeIn>
                          <FadeIn duration={200}>
                            <h1 className="hero__title">We Solve Problems</h1>
                          </FadeIn>

                          <FadeIn duration={200}>
                            <p className="hero__sub-title">
                              By understanding user behaviour, technology and
                              data, we drive your business success.
                            </p>
                          </FadeIn>

                          <FadeIn duration={200}>
                            <PrimaryButton
                              onClick={() =>
                                castStudySectionRef.current.scrollIntoView({
                                  behavior: "smooth",
                                })
                              }
                              iconTransition
                            >
                              Find out more <FiArrowDown />
                            </PrimaryButton>

                            <button
                              className="btn btn-primary play-btn"
                              onClick={promoModal}
                            >
                              Play video
                            </button>
                          </FadeIn>
                        </FadeUpBox>,
                      ]}
                    </PoseGroup>
                  </Col>
                </Row>
              </Container>
            </div>
            <div id="cases-scene-trigger"></div>
          </div>
          <Row>
            <Col>
              <ScrollIndicator />
            </Col>
          </Row>
        </section>

        <section id="case-study" ref={castStudySectionRef}>
          <Container>
            <Row>
              <Col lg={6} sm={12} className="inside-wrapper">
                <div className="information">
                  <h6>Case study</h6>
                  <h3>Don't make me think!</h3>
                  <p>
                    We provide innovative solutions that simplify complex
                    problems.
                  </p>
                  <a className="btn btn-primary" href="/our-work">
                    View all case studies
                  </a>
                </div>
              </Col>

              <Col lg={6} sm={12}>
                <div className="carousel-container">
                  <CaseCarousel
                    caseList={[
                      {
                        title: "Sitefinity Custom Development",
                        clientName: "Wesfarmers Sustainability",
                        backgroundImg:
                          homeImages.wesfarmersSustainability.childImageSharp.fluid.src,
                        clientLogoUrl:
                          homeImages.wesfarmersLogo.childImageSharp.fluid.src,
                        casePageUrl: "/our-work/wesfarmers-sustainability",
                      },
                      {
                        title: "Sitefinity Custom Development",
                        clientName: "Murdoch Course Page",
                        backgroundImg:
                          homeImages.murdochCourse.childImageSharp.fluid.src,
                        clientLogoUrl:
                          homeImages.murdochLogo.childImageSharp.fluid.src,
                        casePageUrl: "/our-work/murdoch-course",
                      },
                      {
                        title: "Sitefinity CMS Build",
                        clientName: "Invest and Trade",
                        backgroundImg:
                          homeImages.investTrade.childImageSharp.fluid.src,
                        clientLogoUrl:
                          homeImages.investTradeLogo.childImageSharp.fluid.src,
                        casePageUrl: "/our-work/investtrade",
                      },
                      {
                        title: "Visibuy Website",
                        clientName: "Pharmacy777",
                        backgroundImg:
                          homeImages.visibuy.childImageSharp.fluid.src,
                        clientLogoUrl:
                          homeImages.visibuyLogo.childImageSharp.fluid.src,
                        casePageUrl: "/our-work/visibuy",
                      },
                      {
                        title: "Kentico to Wordpress Migration",
                        clientName: "Pindan",
                        backgroundImg:
                          homeImages.Pindan.childImageSharp.fluid.src,
                        clientLogoUrl:
                          homeImages.pindanLogo.childImageSharp.fluid.src,
                        casePageUrl: "/our-work/pindan",
                      },
                      {
                        title: "Client Portal Rebuild",
                        clientName: "Energy Action",
                        backgroundImg:
                          homeImages.energyAction.childImageSharp.fluid.src,
                        clientLogoUrl:
                          homeImages.energyActionLogo.childImageSharp.fluid.src,
                        casePageUrl: "/our-work/energy-action",
                      },
                      {
                        title: "Support & Development",
                        clientName: "Wesfarmers",
                        backgroundImg:
                          homeImages.wesfarmers.childImageSharp.fluid.src,
                        clientLogoUrl:
                          homeImages.wesfarmersLogo.childImageSharp.fluid.src,
                        casePageUrl: "/our-work/wesfarmers",
                      },
                      {
                        title: "Volunteer Accounting System (VAS)",
                        clientName: "St John Ambulance",
                        backgroundImg:
                          homeImages.stJohnVas.childImageSharp.fluid.src,
                        clientLogoUrl:
                          homeImages.stJohnLogo.childImageSharp.fluid.src,
                        casePageUrl:
                          "/our-work/stjohn-ambulance/volunteer-accounting-system",
                      },
                      {
                        title: "BEATS Internal News Site",
                        clientName: "St John Ambulance",
                        backgroundImg:
                          homeImages.stJohn.childImageSharp.fluid.src,
                        clientLogoUrl:
                          homeImages.stJohnLogo.childImageSharp.fluid.src,
                        casePageUrl: "/our-work/stjohn-ambulance/beats",
                      },
                      {
                        title: "Support, Development, and Integration",
                        clientName: "Murdoch university",
                        backgroundImg:
                          homeImages.murdoch.childImageSharp.fluid.src,
                        clientLogoUrl:
                          homeImages.murdochLogo.childImageSharp.fluid.src,
                        casePageUrl: "/our-work/murdoch-university",
                      },
                    ]}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section id="information2-scene-trigger">
          <Container>
            <Controller>
              <Scene
                triggerElement="#information2-scene-trigger"
                reverse={false}
              >
                {(progress, event) => (
                  <Row>
                    <div className="video-header">
                      <Col sm={12} lg={5}>
                        <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                          <FadeIn>
                            <h6>Usability</h6>
                            <h3>
                              Creating better
                              <span>user experiences</span>
                            </h3>
                          </FadeIn>
                        </FadeUpBox>
                      </Col>

                      <Col sm={12} lg={7}>
                        <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                          <FadeIn>
                            <h5>
                              Did you know research shows that, on average,
                              every $1 invested in UX brings $100 in return.{" "}
                              <span>That’s an ROI of a whopping 9,900%.</span>
                            </h5>
                          </FadeIn>
                        </FadeUpBox>
                      </Col>
                    </div>

                    <Col sm={12} lg={7} className="video">
                      <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                        <FadeIn>
                          <div className="videoplaceholder">
                            {" "}
                            <video playsInline autoPlay loop muted width="100%">
                              <source src={natureVideo} type="video/mp4" />
                            </video>
                          </div>
                        </FadeIn>
                      </FadeUpBox>
                    </Col>
                    <Col sm={12} lg={5}>
                      <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                        <FadeIn>
                          <div>
                            <p>
                              Focusing on how people experience technology, our
                              consultative approach means you form an integral
                              part of our solution creation process. Our goal is
                              to become your trusted adviser and help you
                              navigate the digital revolution. Mapping your
                              strategic business goals to effective technical
                              solutions that deliver measurable success is how
                              we achieve that.
                            </p>
                          </div>
                          <PrimaryButton
                            to="/user-experience"
                            className="btn btn-primary"
                          >
                            LEARN MORE
                          </PrimaryButton>
                        </FadeIn>
                      </FadeUpBox>
                    </Col>
                  </Row>
                )}
              </Scene>
            </Controller>
          </Container>
        </section>

        <section id="services-scene-trigger">
          <Container>
            <ServicesList />
          </Container>
        </section>
        <section id="information-scene-trigger">
          <Container>
            <Row>
              <Col sm={12}>
                <Controller>
                  <Scene
                    triggerElement="#information-scene-trigger"
                    reverse={false}
                  >
                    {(progress, event) => (
                      <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                        <FadeIn>
                          <h6>Technical</h6>
                          <h3>
                            Delivering meaningful &amp; measurable business
                            outcomes through innovative technical development
                          </h3>
                        </FadeIn>
                      </FadeUpBox>
                    )}
                  </Scene>
                </Controller>
              </Col>

              <div className="text-image-block">
                <Col md={12} lg={6} className="text">
                  <Controller>
                    <Scene
                      triggerElement="#information-scene-trigger"
                      reverse={false}
                    >
                      {(progress, event) => (
                        <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                          <FadeIn>
                            <div>
                              <p>
                                Founded by a group of experienced tech
                                professionals who believe in providing
                                cost-effective enterprise-grade digital
                                solutions, we take a client-centric approach to
                                digital transformation.
                              </p>
                              <p>
                                We are known for crafting elegant, creative
                                solutions for complex problems that simply work,
                                leveraging technology to bring people closer
                                together through communication and
                                accessibility.
                              </p>

                              <PrimaryButton
                                to="/contactus"
                                className="btn btn-primary"
                              >
                                Find out more
                              </PrimaryButton>
                            </div>
                          </FadeIn>
                        </FadeUpBox>
                      )}
                    </Scene>
                  </Controller>
                </Col>
                <Col md={12} lg={6} className="graphic-icon">
                  <Controller>
                    <Scene
                      triggerElement="#information-scene-trigger"
                      reverse={false}
                    >
                      {(progress, event) => (
                        <FadeUpBottom
                          pose={progress > 0 ? "enter" : "exit"}
                          duration={1500}
                          className="d-flex flex-column justify-content-center mb-5 h-sm-auto"
                        >
                          <img
                            src={technicalGraphic}
                            alt="At the edge of Technology"
                          />
                        </FadeUpBottom>
                      )}
                    </Scene>
                  </Controller>
                </Col>
              </div>
            </Row>
          </Container>
        </section>

        <div id="video-promo" className="diviv-modal outer ">
          <div className="diviv-modal__inner color-block">
            <div className="diviv-modal-content">
              <button className="closeBtn" onClick={cancelBtn2}>
                {" "}
                <img src={closeBtn} alt="Close" />
              </button>

              <div className="video-container">
                <video controls width="100%" poster={videoPosterIntro}>
                  <source src={introVideo} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        </div>

        <MobileNav />

        <img
          src={homeImages.divivLogo.childImageSharp.fluid.src}
          alt="diviv-logo-hidden"
          className="d-none"
        />
      </HomeLayout>
    </>
  );
}

export default Index;
