import React from "react";
import { FadeIn } from "../shared/transitions";
import { Link } from "@reach/router";

export const ServicesListItem = props => {
  const { title, description, icon, offsetClass, link } = props;

  return (
    <FadeIn>
      <Link to={link} className="list--services__item">
        <div
          className={
            "list--services__inner list--services__inner" +
            (offsetClass ? `--${offsetClass}` : "")
          }
        >
          <span>{icon}</span>
          <h5>{title}</h5>
          <p>{description}</p>
          <p> Learn More</p>
        </div>
      </Link>
    </FadeIn>
  );
};

export interface IServicesListItem {
  title: string;
  description: string;
  icon: string;
  link?: string;
}
