import React, { useState, useEffect } from "react";
import { ServicesListItem, IServicesListItem } from "./services-list-item";
import { Row, Col } from "react-bootstrap";
import {
  IoIosCloud,
  IoIosCode,
  IoIosTrendingUp,
  IoMdLaptop,
} from "react-icons/io";
import { Scene, Controller } from "react-scrollmagic";
import { FadeIn, FadeUpBox } from "../shared/transitions";

export const ServicesList = props => {
  const { servicesList } = props;
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimate(true);
    }, 2000);

    return () => clearTimeout(timer);
  });

  return (
    <div className="list list-services">
      <svg
        style={{ width: 0, height: 0, position: "absolute" }}
        aria-hidden="true"
        focusable="false"
      >
        <linearGradient id="service-item-gradient" x2="1" y2="1">
          <stop offset="30%" stopColor="#078996" />
          <stop offset="100%" stopColor="#fff" />
        </linearGradient>
      </svg>
      <Controller>
        <Scene triggerElement="#services-scene-trigger" reverse={false}>
          {(progress, event) => (
            <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
              <div className="services-list__container">
                <Row>
                  <Col sm={12}>
                    <Controller>
                      <Scene
                        triggerElement="#services-scene-trigger"
                        reverse={false}
                      >
                        {(progress, event) => (
                          <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                            <FadeIn>
                              <h6>Services</h6>
                              <h3 className="font-weight-bold">
                                We deliver perpetual excellence across our wide range 
                                of products and services
                          </h3>
                            </FadeIn>
                          </FadeUpBox>
                        )}
                      </Scene>
                    </Controller>
                  </Col>
                  <Col xs={12} md={6}>
                    <ServicesListItem
                      link="services#application-development"
                      title="Application Development"
                      description="We help you build solutions that give you a competitive edge in today’s digitally driven world"
                      icon={
                        <IoIosCode
                          size={50}
                          className="list--services__item__icon"
                        />
                      }
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <ServicesListItem
                      link="services#digital-business-strategy"
                      title="Digital Business Strategy"
                      description="Combining the expertise of leading developers, business architects, and digital marketing specialists, we deliver a digital business strategy that works"
                      icon={
                        <IoIosTrendingUp
                          size={50}
                          className="list--services__item__icon"
                        />
                      }
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <ServicesListItem
                      link="services#website-development-and-maintenance"
                      title="Website Development & Maintenance"
                      description="Taking a brand-centric approach, we build websites around what your customers need and what your brand wants to say"
                      icon={
                        <IoMdLaptop
                          size={50}
                          className="list--services__item__icon"
                        />
                      }
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <ServicesListItem
                      link="services#business-system-integration"
                      title="Business System Integration"
                      description="Our business system integration solutions help you transform processes and technology investments into innovative solutions"
                      icon={
                        <IoIosCloud
                          size={50}
                          className="list--services__item__icon"
                        />
                      }
                    />
                  </Col>
                </Row>
              </div>
            </FadeUpBox>
          )}
        </Scene>
      </Controller>
    </div>
  );
};
